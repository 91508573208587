
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { WandIcon } from "vue-tabler-icons";
  import { apiRecover } from "../api";

  @Component({
    components: { LzButton, WandIcon }
  })
  export default class Recover extends Vue {
    recoverForm = {
      email: ""
    };

    loadingPostRecover = false;
    recoverCompleted = false;

    onRecoverSubmit() {
      this.loadingPostRecover = true;

      apiRecover({
        email: this.recoverForm.email
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(async (response: any) => {
          this.recoverCompleted = true;
        })
        .catch((error: any) => this.handleError(error))
        .finally(() => (this.loadingPostRecover = false));
    }

    handleError(error: any): void {
      let text = this.$tc("common.error.generic");
      switch (error.response.status) {
        case 404:
          text = this.$tc("auth.recover.errors.userNotFound");
      }

      this.$notify({
        type: "error",
        text: text
      });
    }
  }
